/**
 * @flow
 */

import createReducer from '../createReducer';
import type { TBenefitState } from './TBenefitState';
import type { THandler } from '../types/THandler';
import type { TBenefitData } from '../types/models/TBenefit';
import type { TBenefitResponse } from '../types/api/TBenefit';
import supercluster from 'supercluster'


const blankState = () => {
    return ({
        data: {},
        locations: {},
        accounts: {},
        benefitStats: {},
        all: [],
        error: null,
        isLoaded: false,
        hiddenBenefits: {
            all: {},
            list: [],
        }
    })
};

const initialState: TBenefitState = blankState();


const sortByHeading = (a, b) => {
    //console.log("sorting keys " + JSON.stringify(a) + ", " + JSON.stringify(b))
    var nameA = a && a.provider ? a.provider.toLowerCase() : ''
    var nameB = b && b.provider ? b.provider.toLowerCase() : ''
    if (nameA < nameB) //sort string ascending
        return -1
    if (nameA > nameB)
        return 1
    return 0 //default return value (no sorting)

}

const _isLoaded = (accounts, config) => {
    if (config.vendors && config.vendors.length > 0) {
        return (Object.keys(accounts).length > config.vendors.length)
    }

    return Object.keys(accounts).length > 0
}

const _buildBenefitsDataByCategories = (data): TBenefitData => {
    const benefits = {};
    benefits['all'] = [];
    console.log("Categories")
    Object.keys(data).forEach((key) => {

        if (!benefits[data[key].category]) {
            benefits[data[key].category] = []
        }
        benefits[data[key].category].push({ ...data[key], benefitId: key });
        benefits['all'].push({ ...data[key], benefitId: key });
    });


    Object.keys(benefits).forEach((key) => {
        console.log("Sorting benefit " + key)
        benefits[key].sort(sortByHeading)
    });


    return benefits;
};


const _addAccountToBenefits = (data, accountId): TBenefitData => {
    const benefits = {};
    if (data) {
        Object.keys(data).forEach((key) => {
            benefits[key] = ({ ...data[key], sourceAccount: accountId });
        });
    }

    return benefits;
};


const _remapBenefitsBasedOnConfig = (data, config) => {

    if (!config.vendorBenefitCustomCategories) return data

    let modifiedData = data
    Object.keys(config.vendorBenefitCustomCategories).forEach(key => {
        if (data[key]) {
            modifiedData[key].category = config.vendorBenefitCustomCategories[key]
        }
    })

    return modifiedData

}

const _buildAllBenefits = (data: TBenefitResponse): TBenefitData => {
    const benefits = {};
    Object.keys(data).forEach((key) => {
        benefits[key] = ({ ...data[key], benefitId: key });
    });


    return benefits;
};

const _buildLocationsData = (data) => {
    const locations = {};
    Object.keys(data).forEach((key) => {
        locations[key] = data[key].locations;
    });
    return arrangeLocations(locations);

};

const arrangeLocation = (locationArray, key, arranged) => {

    for (var n = 0; n < locationArray.length; n++) {
        const location = locationArray[n];
        if (location && location.latitude && location.longitude && location.heading) {

            const mappedKey = location.latitude + '-' + location.longitude
            if (arranged.mapped[mappedKey]) {
                const index = arranged.mapped[mappedKey].index
                arranged.parsed[index].properties.benefits.push({ name: location.heading, id: key });
            }
            else {

                const new_loc = {
                    type: "Feature",
                    properties: {
                        scalerank: 2,
                        name: location.heading,
                        comment: null,
                        name_alt: null,
                        lat_y: location.latitude,
                        long_x: location.longitude,
                        region: "Norge",
                        subregion: null,
                        featureclass: "poi",
                        benefits: [{ name: location.heading, id: key }],
                    },
                    geometry: {
                        type: "Point",
                        coordinates: [
                            location.longitude,
                            location.latitude
                        ]
                    }
                };

                arranged.parsed.push(new_loc);
                const index = (arranged.parsed.length - 1)
                arranged.mapped[mappedKey] = { index: index }
                arranged.byBenefit[key] = index;
            }

        }

    }
    return arranged;
}

const arrangeLocations = (locations) => {

    let arranged = {
        parsed: [],
        mapped: {},
        byBenefit: {}
    };

    for (var key in locations) {
        const locationArray = locations[key];
        if (!locationArray) continue;

        arranged = arrangeLocation(locationArray, key, arranged);
    }

    let cluster = supercluster({
        radius: 60,
        maxZoom: 13,
    });
    cluster.load(arranged.parsed);

    return cluster;
}


const handlers: THandler<TBenefitState> = {

    LOAD_BENEFIT_STATS_SUCCESS: (state, action) => {

        const data = action.payload;

        let res = {};

        data && Object.keys(data).forEach((key) => {
            if (res[data[key].label]) {
                res[data[key].label]++;
            } else {
                res[data[key].label] = 1;
            }
        });

        console.log("STATS DATA", data);
        console.log("RES", res);

        return {
            ...state,
            benefitStats: res,
        }

    },
    LOAD_BENEFITS_SUCCESS: (state, action) => {
        console.log("LOAD_BENEFITS_SUCCESS")


        if (!action.payload || !action.config) return state

        let accounts = state.accounts

        let benefitsMapped = _remapBenefitsBasedOnConfig(action.payload, action.config)
        accounts[action.metadata] = _addAccountToBenefits(benefitsMapped, action.metadata)

        let merged = {}
        Object.keys(accounts).map((metadata) => {
            merged = { ...merged, ...accounts[metadata] }
        })

        const locations = _buildLocationsData(merged);
        const benefitsByCategories = _buildBenefitsDataByCategories(merged);
        const benefitsAll = _buildAllBenefits(merged);
        return {
            ...state,
            error: null,
            isLoaded: _isLoaded(accounts, action.config),
            data: benefitsByCategories,
            all: benefitsAll,
            locations: locations,
            accounts: accounts
        };

    },

    UPDATE_BENEFIT_SUCCESS: (state, action) => {
        console.log("UPDATE_BENEFIT_SUCCESS")

        let benefitsAll = state.all

        let newBenefit = action.payload.data
        newBenefit.benefitId = action.payload.key

        benefitsAll[newBenefit.benefitId] = newBenefit

        return { ...state, all: benefitsAll };
    },

    RESET_BENEFITS_SUCCESS: (state, action) => {
        console.log("RESET_BENEFITS_SUCCESS")
        return blankState();
    },
    LOAD_VENDOR_VISIBILITY_SUCCESS: (state, action) => {
        console.log("LOAD_VENDOR_VISIBILITY_SUCCESS");

        const data = action.payload;
        let all = {};
        let list = [];

        data && Object.keys(data).forEach((key) => {
            if (data[key]) {
                all[key] = true;
                list.push(key)
            }
        });

        return {
            ...state,
            hiddenBenefits: {
                all: all,
                list: list,
            }
        }
    },
    UPDATE_VENDOR_VISIBILITY_SUCCESS: (state, action) => {
        console.log("UPDATE_VENDOR_VISIBILITY_SUCCESS");
        // TODO: Logic error, needs rework. If not in list, never added.
        const data = action.payload;
        let newList = [];

        state.hiddenBenefits.list.forEach((item) => {
            if (item !== data.key) newList.push(item);
            else if (data.status === true) newList.push(item);
        })

        return {
            ...state,
            hiddenBenefits: {
                all: {
                    ...state.hiddenBenefits.all,
                    [data.key]: data.status,
                },
                list: newList
            }
        }
    },
    LOAD_BENEFIT_ORDER_SUCCESS: (state, action) => {
        const data = action.payload;
        console.log("ORDER_DATA", data);

        return {
            ...state,
            benefitOrder: data,
        };
    }
};

export default createReducer(initialState, handlers);
