/**
 * @flow
 */

import React from 'react';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import {
	buildMessageArchive, buildMessageArchives,
	buildMessageDrafts,
	buildMessages
} from '../../../../common/config/firebase_config/endpoints';
import type {
	TMessageApiData,
	TMessageResponse,
} from '../../../../common/types/api/TMessage';
import MessageItem from './MessageItem';

import Spinner from '../../../components/Spinner'

type
TProps = {
	accountId: number;
	messages: Array < TMessageApiData >;
	onMessagesLoadSuccess: (data: TMessageResponse) => void;
	onMessageDraftsLoadSuccess: (data: TMessageResponse) => void;
	onMessageArchivesLoadSuccess: (data: TMessageResponse) => void;
	isDraft: boolean;
};

class MessageList extends React.Component<void, TProps, void> {
	_getQueries = () => ([{
		endpoint: this.props.isArchive ?
			buildMessageArchives(this.props.accountId) :
			this.props.isDraft ?
				buildMessageDrafts(this.props.accountId) :
				buildMessages(this.props.accountId),
		method: 'on',
		eventType: 'value',
		callback: this.props.isArchive ?
			this.props.onMessageArchivesLoadSuccess :
			this.props.isDraft ?
				this.props.onMessageDraftsLoadSuccess :
				this.props.onMessagesLoadSuccess,
		metadata: this.props.accountId,
	}]);
	
	_getItems = () => {
		return this.props.messages.map((message, i) => {
			return <MessageItem
				key={i}
				isDraft={this.props.isDraft}
				isArchive={this.props.isArchive}
				message={message}
				accountId={this.props.accountId}
				archiveMessage={this.props.archiveMessage}
				restoreArchivedMessage={this.props.restoreArchivedMessage}
				publishMessageDraft={this.props.publishMessageDraft}
				history={this.props.history}
			/>;
		});
	};
	
	render() {
		return (
			<FirebaseContainer queries={this._getQueries()}>
				<div>
					{this.props.messages ?
						this._getItems() :
						<Spinner/>
					}
				</div>
			</FirebaseContainer>
		);
	}
}

export default MessageList;
