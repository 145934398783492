/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import {mx} from '../../../utils';

import Link from '../../StartPage/components/Link';
import SectionLink from '../../StartPage/components/SectionLink';


const Menu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  background: #171717;
  left: 0;
  right: 0;
  top: 4.5em;
  padding: 1em;
  ${mx('lg')} {
    flex-direction: row;
    align-items: center;
    position: static;
    background: none;
    padding: 0;
    }
  `;

const LinkHover = styled.span`
  padding-bottom: 0.5em;
  `;

const ButtonsWrapper = styled.div`
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  ${mx('lg')} {
    padding: 0;
    flex-direction: row;
    }
  `;

const Button = styled(Link)`
  border-radius: 1.5em;
  font-weight: bold;
  color: #fff !important;
	
  &:hover {
    text-decoration: none;
    }
  `;

const AButton = styled.a`
	border-radius: 1.5em;
	font-weight: bold;
	color: #fff !important;
	text-decoration: none;
	//text-transform: uppercase;
	padding: 0.7em 1em;
	margin-bottom: 0.5em;
	
	&:hover {
		text-decoration: none;
		color: #fff;
		
		& > * {
			text-decoration: none;
			color: #fff;
			border-bottom: 2px solid #F41A4D;
		}
	}
	
	&:hover {
		text-decoration: none;
	}
`;

const ActionButton = styled.a`
  border-radius: 1.5em;
  font-weight: bold;
  color: #fff !important;
  &:hover {
    text-decoration: none;
    }
  `;

type TProps = {
  onMenuItemClick: Function;
}

const Navbar = (props : TProps) => (
  <Menu>
    <SectionLink onClick={() => props.onMenuItemClick('promotional')}>
      <LinkHover>Hva er det?</LinkHover>
    </SectionLink>
    <SectionLink onClick={() => props.onMenuItemClick('describe')}>
      <LinkHover>Løsningen</LinkHover>
    </SectionLink>
    <SectionLink onClick={() => props.onMenuItemClick('benefits')}>
      <LinkHover>Hvorfor goder?</LinkHover>
    </SectionLink>
    <SectionLink onClick={() => props.onMenuItemClick('platform')}>
      <LinkHover>Platform</LinkHover>
    </SectionLink>
    <SectionLink onClick={() => props.onMenuItemClick('contact')}>
      <LinkHover>Kontakt</LinkHover>
    </SectionLink>
    <ButtonsWrapper>
      <Button to="register">Våre kunder</Button>
    </ButtonsWrapper>
	  <ButtonsWrapper>
		  <AButton target={"_blank"} href={"https://selfservice-mu.vercel.app"}>Self service</AButton>
	  </ButtonsWrapper>
      <ButtonsWrapper>
          <Button to="help">Hjelp</Button>
      </ButtonsWrapper>
    <ButtonsWrapper>
      <Button to={'/login'}>Sign in</Button>
    </ButtonsWrapper>
  </Menu>
);

export default Navbar;
