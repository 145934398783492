/**
 * @flow
 */

import React, {
    Children,
    Component,
} from 'react';
import {firebaseDb} from '../config/firebase_config/firebase';

type TMethod = 'on' | 'once';
type TEventType = 'value' | 'child_added' | 'child_changed' | 'child_removed' | 'child_moved';
type TQuery = {
    endpoint: string;
    method: TMethod;
    eventType: TEventType;
    callback: Function;
}

type TProps = {
    children?: React.Element<*>;
    queries: Array<TQuery>;
}

/**
 * Wrapper for the components that want to access to the Firebase database
 * It make sures we clean all references from 'on' using 'off'
 */
export default class FirebaseContainer extends Component<void, TProps, void> {

    _refs = [];

    componentDidMount() {
        this.props.queries.forEach(({endpoint, method, eventType, callback, metadata, config}) => {
            console.log("Running Query to firebase:" + endpoint)
            const ref = firebaseDb.ref(endpoint);
            ref[method](eventType, (snapshot) => {
                callback(snapshot.val(), metadata, config);
            });
            if (method !== 'once') {
                ref.once(eventType, (snapshot) => {
                    if (snapshot.val() === null) {
                        console.log(endpoint + " is empty, dispatching callback")
                        callback({}, metadata, config);
                    }
                });
                this._refs.push(ref);
            }
        });
    }

    componentWillUnmount() {
        this._refs.forEach((ref) => {
            ref.off();
        });
    }

    render() {
        return this.props.children;
    }
}
