/**
 * @flow
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {reduxForm, Field, FieldArray, change, formValueSelector} from 'redux-form';
import find from "lodash/find"
import SelectInput from '../../../components/SelectInput'
import {
  Flex,
  Box,
} from 'reflexbox';

import Wrapper from '../../../components/Typografy/Wrapper';
import ImageUploader from '../../../components/form/ImageUploader';

import CustomFieldInput from '../../../components/Typografy/CustomFieldInput';
import CustomFieldTextarea from '../../../components/Typografy/CustomFieldTextarea';
import FormFooter from '../../../components/FormFooter';
import {imageMain, imageMedium} from '../../../../common/config/images/endpoints';
import {buildBenefitImage} from '../../../../common/config/firebase_config/endpoints';

import Steps from './components/Steps'
import Locations from './components/Locations'

import {Card, CardTitle, Container} from '../../../components/Elements'


import type {
  TBenefitApiData,
} from '../../../../common/types/api/TBenefit';
import Translate from "../../../components/form/Translate";
import LimitDivisions from "./components/LimitDivisions";
import ImageUploaderModal from "../../../components/form/ImageUploaderModal";
import ToggleSwitch from "../../../components/ToggleSwitch";
import {hasSuperAdminAccess} from "../../../../common/auth";
import {SaveButton} from "../../../components/Buttons";
import XLSX from "xlsx";
import FileSaver from "file-saver";



type TProps = {
  accountId: string;
  initialValues: TBenefitApiData;
  hasBenefit: boolean;
  isDraft: boolean;
}

type TState = {}


const options = (props) => {
  console.log(props.categories);
  return props.categories.map(category => ({
    label: category.name,
    value: category.categoryId
  }));
}


const TranslatableInputField = (props) => {
    return <Translate {...props} parentField={props.name}  />
}



class BenefitForm extends Component<void, TProps, TState> {


    exportLocations = () => {
        if (!this.props.benefitFormValues) return;
        const data = this.props.benefitFormValues.locations;
        if (data) {

            const dataRows = data.map(row => [row.heading, row.longitude, row.latitude, row.text])

            const mergedRows = [["Heading", "Longitude", "Latitude", "Text"]].concat(dataRows)

            const worksheet = XLSX.utils.aoa_to_sheet(mergedRows);
            const new_workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(new_workbook, worksheet, "Locations");
            const wbout = XLSX.write(new_workbook, {type: 'binary', bookType: "xlsx"});


            FileSaver.saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), this.props.benefitFormValues.heading + "_export-Locations.xlsx");

        }


    }

    s2ab = (s) => {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }


  render() {

      const {accountId, accounts, profile} = this.props;
      const account = accounts[accountId];
      console.log("PROFILE");
      console.log(profile);

    let benefitId = this.props.benefit ? this.props.benefit.benefitId : ''
    return (
      <div>
          <Container>
              <Wrapper>
                  <Flex wrap>
                      <Box w={[1]}>
                          <Card wrap={true}>
                              <Box w={[1]}>
                                  <CardTitle>
                                      Vendor profile
                                  </CardTitle>
                              </Box>
                              <Box w={[1]}>
                                  <form autoComplete="off">
                                      <div>
                                          <Field
                                              name="provider"
                                              component={CustomFieldInput}
                                              type="text"
                                              placeholder="Name"
                                              userCard
                                              label="* Name"
                                          />
                                      </div>
                                      <div>
                                          <Field
                                              name="contactWebsite"
                                              component={CustomFieldInput}
                                              placeholder="Website"
                                              type="text"
                                              userCard
                                              label="Website"
                                          />
                                          <Field
                                              name="contactPhone"
                                              component={CustomFieldInput}
                                              placeholder="Phone"
                                              type="tel"
                                              userCard
                                              label="Phone"
                                          />
                                          <Field
                                              name="contactEmail"
                                              component={CustomFieldInput}
                                              placeholder="Email"
                                              type="Email"
                                              userCard
                                              label="* Email"
                                          />
                                      </div>
                                  </form>
                              </Box>
                          </Card>
                          {this.props.hasBenefit && <ImageUploaderModal
                              uploadUrl={buildBenefitImage(this.props.accountId, this.props.initialValues.benefitId)}
                              showUrl={this.props.benefit ? imageMain(this.props.accountId, this.props.benefit.benefitId) : null}
                              headline={"Benefit Image"} minWidth={500} minHeight={500} aspectRatio={1} />}
                          <form autoComplete="off">
                              <Card wrap>
                                  <Box w={[1]}>
                                      <CardTitle>
                                          Benefit info
                                      </CardTitle>
                                  </Box>
                                  <Box w={[1]}>
                                      {console.log('this.props.accounts[this.props.accountId]')}
                                      {console.log(this.props.accounts[this.props.accountId])}
                                      {account.customModules && account.customModules.benefitReport && account.customModules.benefitReport.active && <Field
                                          name="isReportable"
                                          component={ToggleSwitch}
                                          label="Should benefit usages be logged? (Rapporteringspliktig)"
                                          type="checkbox"
                                      />}
                                      <Field
                                          name="category"
                                          options={options(this.props)}
                                          component={SelectInput}
                                          label="* Category"
                                      />
                                      <TranslatableInputField
                                          name="heading"
                                          component={CustomFieldInput}
                                          type="text"
                                          placeholder="Heading"
                                          userCard
                                          label="* Heading"
                                          formValues={this.props.benefitFormValues} accountId={this.props.accountId}
                                          formChange={this.props.change}
                                          account={account}
                                      />
                                      <TranslatableInputField
                                          name="description"
                                          component={CustomFieldTextarea}
                                          type="text"
                                          placeholder="Description"
                                          userCard
                                          rows={2}
                                          label="* Description"
                                          formValues={this.props.benefitFormValues} accountId={this.props.accountId}
                                          formChange={this.props.change}
                                          account={account}
                                      />
                                      <TranslatableInputField
                                          name="description_long"
                                          component={CustomFieldTextarea}
                                          type="text"
                                          placeholder="Long Description"
                                          userCard
                                          rows={15}
                                          label="* Long description"
                                          formValues={this.props.benefitFormValues} accountId={this.props.accountId}
                                          formChange={this.props.change}
                                          account={account}
                                      />
                                  </Box>
                              </Card>
                              {hasSuperAdminAccess(profile) && <Card wrap>
                                  <Box w={[1]}>
                                      <CardTitle>
                                          One Click Signup
                                      </CardTitle>
                                  </Box>
                                  <Box w={[1]}>
                                      <Field
                                          name="useOneClickSignup"
                                          component={ToggleSwitch}
                                          label="Use One Click Signup?"
                                          type="checkbox"
                                      />
                                      {(this.props.benefitFormValues && (this.props.benefitFormValues.useOneClickSignup)) &&
                                      <div>
                                          <Field
                                              name="ocsRecipient"
                                              component={CustomFieldInput}
                                              placeholder="Email Recipient"
                                              type="text"
                                              label="* Recipient"
                                          />
                                          <Field
                                              name="ocsSubject"
                                              component={CustomFieldInput}
                                              placeholder="Email Subject"
                                              type="text"
                                              label="* Subject"
                                          />

                                          <Flex justify="space-between">
                                              <Box w={[1, 2/3]}>
                                                  <Field
                                                      name="ocsBody"
                                                      component={CustomFieldTextarea}
                                                      type="text"
                                                      placeholder="Email Body"
                                                      userCard
                                                      rows={15}
                                                      label="* Body"
                                                  />
                                              </Box>
                                              <Box w={[1, 1/3]}>
                                                  <h2>How to use</h2>
                                                  <p>In the email template, use the variables provided under to set dynamic values. For instance, "I work for %company%", could translate to "I work for CoveredPro"</p>
                                                  <ul>
                                                      <li>%fullName% = Users full name</li>
                                                      <li>%firstName% = Users first name</li>
                                                      <li>%lastName% = Users last name</li>
                                                      <li>%email% = Users email</li>
                                                      <li>%company% = Company</li>
                                                  </ul>
                                              </Box>
                                          </Flex>
                                      </div>
                                      }
                                  </Box>
                              </Card>}
                              <Card wrap>
                                  <Box w={[1]}>
                                      <CardTitle>
                                          How to get it
                                      </CardTitle>
                                  </Box>
                                  <Box w={[1]}>
                                      <FieldArray name="steps" formValues={this.props.benefitFormValues}
                                                  accountId={this.props.accountId} accountsList={this.props.accountsList} component={Steps}/>
                                  </Box>
                              </Card>
                              <Card wrap>
                                  <Box w={[1]}>
                                      <CardTitle>

                                          Where to get it
                                      </CardTitle>
                                      {/*<SaveButton type="button" onClick={() => this.exportLocations()}>Export list as CSV</SaveButton>*/}
                                  </Box>
                                  <Box w={[1]}>
                                      {/*<FieldArray name="locations" formValues={this.props.benefitFormValues} component={Locations}/>*/}
                                      <Field
                                          name="location"
                                          component={CustomFieldInput}
                                          type="text"
                                          placeholder="Location URL"
                                          userCard
                                          label="Location URL"
                                      />
                                  </Box>
                              </Card>

                              <LimitDivisions {...this.props}/>
                          </form>
                      </Box>
                  </Flex>
              </Wrapper>
          </Container>
          <FormFooter to={ this.props.isDraft ? `/admin/benefits-draft/${benefitId}` : `/admin/benefits/${benefitId}`} pristine={this.props.pristine}
                      actionText={"Save"}
                      onSave={() => {
                          this.props.isDraft ? this.props.updateDraftBenefit(this.props.benefitFormValues, this.props.benefit) : this.props.updateBenefit(this.props.benefitFormValues, this.props.benefit)
                      }
                      }/>
      </div>
    );
  }
}


BenefitForm = reduxForm({
    form: 'BenefitForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(BenefitForm);



export default  connect(null)(BenefitForm);
