/**
 * @flow
 */

import React, { Component } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import Link from '../../../components/Typografy/Link';
import ArrowIcon from './components/ArrowIcon';
import MessageCard from './MessageCard';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import { buildMessages } from '../../../../common/config/firebase_config/endpoints';
import type {
  TMessageApiData,
  TMessageResponse,
} from '../../../../common/types/api/TMessage';

const Container = styled.div`
  background-image: url(${require('../../../assets/images/top-background.png')});
  padding-top: 1.6em;
`;

const BackLink = styled(Link)`
   padding: 2em 0 2em 0;
`;

type TProps = {
  accountId: number;
  messages: TMessageApiData;
  onMessagesLoadSuccess: (data: TMessageResponse) => void;
  params: Object;
};

class MessageDetails extends Component<void, TProps, void> {
  _getQueries = () => {
    return ([{
      endpoint: buildMessages(this.props.accountId),
      method: 'on',
      eventType: 'value',
      callback: this.props.onMessagesLoadSuccess,
    },
    ]);
  };
  _getItem = () => {
    const id = this.props.match.params.id;
    const message = _.find(this.props.messages || [], b => b.messageId === id);
    if (!message) return (<div></div>);



    return (
      <div>
        <Container>
          <Wrapper>
            <BackLink to="/admin/messages">
              <ArrowIcon />
              Messages
            </BackLink>
            <MessageCard onArchive={this.props.onArchive} message={message} accountId={this.props.accountId} />
          </Wrapper>
        </Container>
      </div>
    );
  }

  render() {
    return (
      <div>
        <FirebaseContainer queries={this._getQueries()}>
          {this.props.messages ? this._getItem() : null}
        </FirebaseContainer>
      </div>
    );
  }
}


export default MessageDetails;

