import React, {Component} from 'react';
import {connect} from 'react-redux';
import {change, Field, FieldArray, reduxForm} from 'redux-form';


import {Box, Flex,} from 'reflexbox';

import Wrapper from '../../../components/Typografy/Wrapper';
import ImageUploader from '../../../components/form/ImageUploader';

import CustomFieldInput from '../../../components/Typografy/CustomFieldInput';
import FormFooter from '../../../components/FormFooter';
import {buildAccountLogo, readAccountLogo} from '../../../../common/config/firebase_config/endpoints';

import {Card, CardTitle, Container} from '../../../components/Elements'
import ToggleSwitch from '../../../components/ToggleSwitch'

import VendorsOnCard from './components/VendorsOnCard'
import Vendors from './components/Vendors'
import IncludedAccounts from './components/IncludedAccounts'
import Categories from './components/Categories'
import SplashBuilder from './components/SplashBuilder'
import FeatureGraphicBuilder from './components/FeatureGraphicBuilder'
import Divisions from "./components/Divisions";
import SupportedLanguages from "./components/SupportedLanguages";
import ThemeBuilder from "./components/ThemeBuilder";
import IconBuilder from "./components/IconBuilder";
import AppcodeUploader from './components/AppcodeUploader'
import SelectInput from "../../../components/SelectInput";
import MembershipTemplateUploader from "./components/MembershipTemplateUploader";
import MessageVendors from "./components/MessageVendors";


const getFieldValueFromForm = (base, subfield1, subfield2, formValues) => {
	
	const data = formValues || {}
	
	if (data[base]) {
		if (subfield1 && data[base][subfield1]) {
			if (subfield2 && data[base][subfield1][subfield2]) {
				return data[base][subfield1][subfield2]
			} else {
				return data[base][subfield1]
			}
			
		}
		return true
	}
	
	return false
}

const entitlementModels = [{label: "Standard", value: "standard"}, {
	label: "Akademikerne A+",
	value: "apluss"
}, {label: "TradeBroker kundeklubb", value: "tradebroker"}]

const entitlementOptions = (props) => {
	
	return entitlementModels
}

const options = (accounts, self) => {
	if (!accounts) return []
	
	return Object.keys(accounts).filter((acccountId) => {
		return (acccountId !== self)
	}).map((acccountId) => ({
		label: accounts[acccountId].name,
		value: acccountId
	}));
}

class AccountForm extends Component {
	
	state = {
		pristine: false,
	}

	forceUpdate = () => {
		this.setState({pristine: false})
	}
	getFieldValue = (base, subfield1, subfield2) => {
		return getFieldValueFromForm(base, subfield1, subfield2, this.props.accountFormValues)
	}
	
	
	renderWithAccount() {
		return (
			<div>
				{this.renderCustomModules()}
				<MembershipTemplateUploader update={this.props.updateMembershiptTemplate}
				                            accountId={this.props.accountId} account={this.props.account}/>
				
				<SupportedLanguages {...this.props}/>
				<Field
					uploadUrl={buildAccountLogo(this.props.accountId)}
					showUrl={this.props.account ? readAccountLogo(this.props.accountId) : null}
					headline={"Logo Image"}
					minWidth={500}
					minHeight={200}
					component={ImageUploader}
					name="logoUrl"
				/>
				
				<IconBuilder {...this.props} />
				
				<SplashBuilder {...this.props}/>
				<FeatureGraphicBuilder {...this.props}/>
				
				<form autoComplete="off">
					{this.getCardInfo()}
					
					{this.props.account.isMultiAccount && this.getIncludedAccounts()}

					{this.getBenefitVendors()}
					
					{this.getBenefitMessageVendors()}
					
					{(this.props.accountFormValues && !(this.props.accountFormValues.defaultCategories)) &&
						<Categories {...this.props} />}
					<Divisions {...this.props}/>
					
					<Field component={ThemeBuilder}
					       name="theme.overrides"
					       forceUpdate={this.forceUpdate}
					       {...this.props}
					       width={'' + this.props.width}
					       height={'' + this.props.height}
					/>
					<AppcodeUploader update={this.props.updateAppCodes} appCodeStatus={this.props.appCodeStatus}
					                 accountId={this.props.accountId} account={this.props.account}/>
				</form>
			</div>
		)
	}
	
	
	getBenefitVendors() {
		return <Card wrap={true}>
			<Box w={[1]}>
				<CardTitle>
					Benefit vendors to include
				</CardTitle>
			</Box>
			<Box w={[1]}>
				<FieldArray name="vendors" accountId={this.props.accountId} accounts={this.props.accounts}
				            component={Vendors}/>
			</Box>
		</Card>;
	}

	getIncludedAccounts() {
		return <Card wrap={true}>
			<Box w={[1]}>
				<CardTitle>
					Accounts included in multi account app
				</CardTitle>
			</Box>
			<Box w={[1]}>
				<FieldArray name="includedAccountsPrepared" accountId={this.props.accountId} accounts={this.props.accounts}
				            component={IncludedAccounts}/>
			</Box>
		</Card>;
	}
	
	getBenefitMessageVendors() {
		return <Card wrap={true}>
			<Box w={[1]}>
				<CardTitle>
					Forward messages to accounts
				</CardTitle>
				
				<h4>When this account publishes a new message, it and notifications will also be sent to all accounts added here</h4>
			</Box>
			<Box w={[1]}>
				<FieldArray name="messageVendors" accountId={this.props.accountId} accounts={this.props.accounts}
				            component={MessageVendors}/>
			</Box>
		</Card>;
	}
	
	getCardInfo() {
		return <Card wrap={true}>
			<Box w={[1]}>
				<CardTitle>
					Card info
				</CardTitle>
			</Box>
			<Box w={[1]}>
				<div>
					<Field
						name="companyNameOnCard"
						component={CustomFieldInput}
						type="text"
						placeholder="Company Name"
						userCard
						label="* Company name on card"
					/>
				</div>
			</Box>
			<Box w={[1]}>
				<FieldArray name="vendorsOnCard" component={VendorsOnCard}/>
			</Box>
		</Card>;
	}
	
	render() {
		
		let accountId = this.props.account ? this.props.account.accountId : ''
		
		return (
			<div>
				<Container>
					<Wrapper>
						<Flex wrap>
							<Box w={[1]}>
								{this.renderAccountInfo()}
								{this.props.hasAccount && (!this.getFieldValue('disabled')) && this.renderWithAccount()}
							</Box>
						</Flex>
					</Wrapper>
				</Container>
				<FormFooter to={`/admin/accounts/${accountId}`} pristine={this.props.pristine && this.state.pristine}
				            onSave={() => {
					            this.props.updateAccount(this.props.accountFormValues, this.props.account);
					            this.setState({pristine: true})
				            }}/>
			</div>
		
		);
	}
	
	renderAccountInfo() {
		return <Card wrap={true}>
			<Box w={[1]}>
				<CardTitle>
					Account info
				</CardTitle>
			</Box>
			<Box w={[1]}>
				<form autoComplete="off">
					<div>
						<Field
							name="customerName"
							component={CustomFieldInput}
							type="text"
							placeholder="Name"
							userCard
							label="* Customer name"
						/>
					</div>
					<div>
						<Field
							name="name"
							component={CustomFieldInput}
							placeholder="App name"
							type="text"
							userCard
							label="* App name"
						/>
					
					</div>
					<div>
						<Field
							name="appId"
							component={CustomFieldInput}
							placeholder="company.appname"
							type="text"
							userCard
							label="* Company . App name"
						/>
					
					</div>
					<div>
						<Field
							name="customerUrl"
							component={CustomFieldInput}
							placeholder="companyname"
							type="text"
							userCard
							label="* Customer URL"
						/>
					
					</div>
					<div>
						<Field
							name="customerContactEmail"
							component={CustomFieldInput}
							placeholder="contact@customer.com"
							type="text"
							userCard
							label="* Customer contact email"
						/>
					</div>
					<Field
						name="isMultiAccount"
						component={ToggleSwitch}
						label="Multi Account"
						type="checkbox"
					/>
					<Field
						name="vendorAccount"
						component={ToggleSwitch}
						label="This is a vendor account"
						type="checkbox"
					/>
					<Field
						name="defaultCategories"
						component={ToggleSwitch}
						label="Use default categories"
						type="checkbox"
					/>
					<Field
						name="enterpriseDistribution"
						component={ToggleSwitch}
						label="Use enterprise distribution channel"
						type="checkbox"
					/>
					
					<Field
						name="commonAppDistribution"
						component={ToggleSwitch}
						label="Use Tradebroker Avtaler App for distribution"
						type="checkbox"
					/>
					<Field
						name="disabled"
						component={ToggleSwitch}
						label="Deactivate account"
						type="checkbox"
					/>
					<Field
						name="passwordless"
						component={ToggleSwitch}
						label="Passwordless login"
						type="checkbox"
					/>
					<Field
						name="customEntitlementsResolution"
						component={ToggleSwitch}
						label="Custom entitlements resolution"
						type="checkbox"
					/>
					{this.getFieldValue('customEntitlementsResolution') === true &&
						<div>
							<Field
								name="entitlementsResolution"
								options={entitlementOptions(this.props)}
								component={SelectInput}
								label="Choose entitlements resolution"
							/>
							<Field
								name="entitlementsMapping"
								component={CustomFieldInput}
								placeholder="Apluss account name"
								type="text"
								userCard
								label="* App name"
							/></div>
					}
				</form>
			</Box>
		</Card>;
	}
	
	renderCustomModules() {
		return <Card wrap={true}>
			<Box w={[1]}>
				<CardTitle>
					Custom modules
				</CardTitle>
			</Box>
			<Box w={[1]}>
				<form autoComplete="off">
					<Field
						name="customModules.benefitReport.active"
						component={ToggleSwitch}
						label="Benefit logging module"
						type="checkbox"
					/>
					<Field
						name="customModules.cabinBooking.active"
						component={ToggleSwitch}
						label="Cabin booking module"
						type="checkbox"
					/>
					<Field
						name="customModules.webApp.active"
						component={ToggleSwitch}
						label="Benefits Webapp"
						type="checkbox"
					/>
				
				</form>
			</Box>
		</Card>;
	}
}

export default connect(
	null, {change})
(reduxForm({
	form: 'AccountForm',
})(AccountForm));
