/**
 * @flow
 */
import { firebaseDb } from '../config/firebase_config/firebase';
import {
	buildNewMessage,
	buildMessage,
	buildMessageArchive, buildNewMessageDraft, buildMessageDraft
} from '../config/firebase_config/endpoints';

import history from '../utils/history'
import { NotificationManager } from 'react-notifications';

export const onMessagesLoadSuccess = payload => ({
	type: 'LOAD_MESSAGES_SUCCESS',
	payload,
});

export const onMessageDraftsLoadSuccess = payload => ({
	type: 'LOAD_MESSAGE_DRAFTS_SUCCESS',
	payload,
});

export const onMessageArchivesLoadSuccess = payload => ({
	type: 'LOAD_MESSAGE_ARCHIVES_SUCCESS',
	payload,
})

export const onTokensLoadSuccess = payload => ({
	type: 'LOAD_TOKENS_SUCCESS',
	payload,
});


function updateMessageRequest() {
	return {
		type: 'UPDATE_MESSAGE_REQUEST',
	};
}

function updateMessageSuccess() {
	return {
		type: 'UPDATE_MESSAGE_SUCCESS',
	};
}

function updateMessageError(json) {
	return {
		type: 'UPDATE_MESSAGE_ERROR',
	};
}

function updateMessageDraftRequest() {
	return {
		type: 'UPDATE_MESSAGE_REQUEST',
	};
}

function updateMessageDraftSuccess() {
	return {
		type: 'UPDATE_MESSAGE_SUCCESS',
	};
}

function updateMessageDraftError(json) {
	return {
		type: 'UPDATE_MESSAGE_ERROR',
	};
}

export const onMessagesReset = () => ({
	type: 'RESET_MESSAGES_SUCCESS',
});

const getNowAsObject = () => {
	var today = new Date();
	var day = today.getDate();
	var month = today.getMonth() + 1; //January is 0!
	var year = today.getFullYear();

	var minutes = today.getMinutes()
	var hours = today.getHours()
	var seconds = today.getSeconds()

	return {
		day,
		month,
		year,
		hours,
		minutes,
		seconds
	}

}


export const updateMessage = (formValues, message, accounts) => async (dispatch, getState) => {
	try {
		dispatch(updateMessageRequest());
		const store = getState();
		const accountId = store.auth.profile.data.accountId;

		let updates = {};

		let messageKey = '';

		if (message) {
			messageKey = message.messageId;
		} else {
			const newMessageEndpoint = buildNewMessage(accountId);
			messageKey = firebaseDb.ref().child(newMessageEndpoint).push().key;
		}

		let storableData = JSON.parse(JSON.stringify(formValues));
		storableData.date = getNowAsObject();

		updates[buildMessage(accountId, messageKey)] = storableData;

		if (accounts) {
			for (let accId of accounts) {
				updates[buildMessage(accId, messageKey)] = {
					...storableData,
					originAccountId: accountId,
				};
			}
		}

		console.log("update message called");
		await firebaseDb.ref().update(updates);

		dispatch(updateMessageSuccess());
		NotificationManager.success('Success!', 'Message saved!', 3000);
		history.push('/admin/messages');

	} catch (err) {
		console.log("update message ERROR: " + JSON.stringify(err));

		dispatch(updateMessageError());
	}
};

export const updateMessageDraft = (formValues, message) => async (dispatch, getState) => {
	try {
		dispatch(updateMessageDraftRequest());
		const store = getState();
		const accountId = store.auth.profile.data.accountId;

		let updates = {};

		let messageKey = ''

		if (message) {
			messageKey = message.messageId;
		} else {
			const newMessageDraftEndpoint = buildNewMessageDraft(accountId);
			messageKey = firebaseDb.ref().child(newMessageDraftEndpoint).push().key;
		}

		let storableData = JSON.parse(JSON.stringify(formValues));
		storableData.date = getNowAsObject()

		updates[buildMessageDraft(accountId, messageKey)] = storableData;

		console.log("update message called")
		await firebaseDb.ref().update(updates);

		dispatch(updateMessageDraftSuccess());
		NotificationManager.success('Success!', 'Message saved as draft!', 3000);
		history.push('/admin/messages/drafts')

	} catch (err) {
		console.log("update message ERROR: " + JSON.stringify(err))

		dispatch(updateMessageDraftError());
	}
};


export const archiveMessage = (message, xaccountId, isDraft = false) => async (dispatch, getState) => {
	try {
		dispatch(updateMessageRequest());
		const store = getState();
		const accountId = store.auth.profile.data.accountId;
		const categoryId = message.category;
		let messageKey = message.messageId;

		let updates = {};

		updates[buildMessageArchive(accountId, messageKey)] = message;
		updates[(isDraft ? buildMessageDraft : buildMessage)(accountId, messageKey)] = null;

		if (!isDraft) {
			const accountRef = await firebaseDb.ref(`accounts/${accountId}`).once('value');
			const account = accountRef.val();
			if (account.messageVendors && account?.messageVendors?.length > 0) {
				for (const vendor of account.messageVendors) {
					const ref = await firebaseDb.ref(buildMessage(vendor.accountId, messageKey)).once('value');
					const exists = ref.exists();
					if (exists) {
						updates[buildMessage(vendor.accountId, messageKey)] = null;
					}
				}
			}
		}

		await firebaseDb.ref().update(updates);

		dispatch(updateMessageSuccess());
		NotificationManager.success('Success!', 'Message archived!', 3000);
		history.replace(isDraft ? "/admin/messages/drafts" : '/admin/messages');

	} catch (err) {
		dispatch(updateMessageError());
	}
};

export const restoreArchivedMessage = (message) => async (dispatch, getState) => {
	try {
		dispatch(updateMessageRequest());
		const store = getState();
		const accountId = store.auth.profile.data.accountId;
		let messageKey = message.messageId;

		let updates = {};

		updates[buildMessageDraft(accountId, messageKey)] = message;
		updates[buildMessageArchive(accountId, messageKey)] = null;

		await firebaseDb.ref().update(updates);

		console.log("restore archived message", updates);

		dispatch(updateMessageSuccess());
		NotificationManager.success('Success!', 'Message restored to draft!', 3000);
		history.replace('/admin/messages/drafts');

	} catch (err) {
		dispatch(updateMessageError());
	}
}

export const publishMessageDraft = (message, accounts, notification) => async (dispatch, getState) => {
	try {
		dispatch(updateMessageRequest());
		const store = getState();
		const accountId = store.auth.profile.data.accountId;
		let messageKey = message.messageId;

		let updates = {};

		updates[buildMessage(accountId, messageKey)] = {
			...message,
			text: notification ? notification : null,
			sendNotification: notification ? true : false,
		};
		updates[buildMessageDraft(accountId, messageKey)] = null;

		if (accounts) {
			for (let accId of accounts) {
				updates[buildMessage(accId, messageKey)] = {
					...message,
					originAccountId: accountId,
					text: notification ? notification : null,
					sendNotification: notification ? true : false,
				};
			}
		}

		await firebaseDb.ref().update(updates);

		console.log("publish message draft");

		dispatch(updateMessageSuccess());
		NotificationManager.success('Success!', 'Message published!', 3000);
		history.replace('/admin/messages');

	} catch (err) {
		dispatch(updateMessageError());
	}
}
