/**
 * @flow
 */

import React from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import SubHeader from './SubHeader';
import theme from '../../../theme/index';
import type {
  TBenefitApiData,
  TBenefitResponse,
} from '../../../../common/types/api/TBenefit';
import BenefitList from './BenefitList';
import {buildCategories, buildAccounts, buildCategoriesByAccount} from "../../../../common/config/firebase_config/endpoints";
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import Spinner from "../../../components/Spinner";
import {getAccounts, getBenefitOrder, getBenefitStats, getCategories, getVendorVisibility} from '../components/Retrievers'
import topBackground from '../../../assets/images/top-background.png';

const BenefitsWrap = styled.div`
  background-color: ${theme.benefits.backgroundColor};
`;


const BenefitBackground = styled.div`
   background-color: rgb(20, 20, 20);
   margin-bottom: 2em;
`;


const CategoriesContainer = styled(Flex)`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
`;

// eslint-disable-next-line no-unused-vars
const CategoryContainer = styled(({ active, ...rest }) => <button {...rest} />)`
  margin-right: 2.75em;
  font-family: 'Open Sans', sans-serif;
  padding-bottom: 0.5em;
  background: transparent;
  border: none;
  cursor: pointer;
  color:  ${props => props.active ?
    `${theme.categories.activeCategoryColor}` :
    `${theme.categories.categoryColor}`};
  border-bottom:  ${props => props.active ?
    `2px solid ${theme.nav.link}` :
    'none'};
  font-size: 1em;
  &:focus {
    outline:0;
  }

`;

type TState = {
  selectedId: string;
};

type TProps = {
  accountId: number;
  benefits: TBenefitApiData;
  onBenefitsLoadSuccess: (data: TBenefitResponse) => void;
};

class BenefitPage extends React.Component<void, TProps, TState> {

  componentDidMount() {
    if (this.props.isDraft || this.props.isArchive) {
      this.props.onBenefitsReset();
    }
  }

  state: TState = {
    selectedId: 'all',
  };

  _selectCategory = (category) => {
    this.setState({ selectedId: category.id });
  };

  _renderCategoryList = () => {
    if(!(this.props.categories && Array.isArray(this.props.categories))) return null

    return this.props.categories.map((category, i) => {
      return (
        <CategoryContainer
          onClick={() => this._selectCategory(category)}
          key={i}
          active={category.id === this.state.selectedId}
        >
          {category.name}
        </CategoryContainer>
      );
    });
  };

  render(){
      return( <FirebaseContainer queries={getAccounts(this.props.onAccountsLoadSuccess)}>
          {this.props.accountsLoaded ? this.innerRender() : <Spinner/>}
      </FirebaseContainer>)
  }

  innerRender() {
    const list = this._renderCategoryList();
    const {accounts, accountId} = this.props
    return (
      <FirebaseContainer queries={[
          ...getCategories(accounts, accountId, this.props.onCategoriesLoadDefaultSuccess, this.props.onCategoriesLoadSuccess),
          ...getVendorVisibility(accountId, this.props.onVendorVisibilityLoadSuccess),
          ...getBenefitOrder(accountId, this.props.onBenefitOrderLoadSuccess)
      ]}>
      <FirebaseContainer queries={getBenefitStats(accountId, this.props.onBenefitStatsLoadSuccess)}>
      <BenefitsWrap>
        <BenefitBackground>
          <SubHeader isArchive={this.props.isArchive} isDraft={this.props.isDraft} account={this.props.accounts[this.props.accountId]}/>
          <Wrapper>
            {(!this.props.isArchive && !this.props.isDraft) && <CategoriesContainer>
              <CategoryContainer
                onClick={() => this._selectCategory({id:'all', name:"All"})}
                key="allcategories"
                active={ 'all' === this.state.selectedId}
              >
                All
              </CategoryContainer>

              {list}
            </CategoriesContainer>}
          </Wrapper>
        </BenefitBackground>
        <Wrapper>
          <BenefitList
            accountId={accountId}
            accounts={accounts}
            benefits={this.props.benefits[this.state.selectedId]}
            onBenefitsLoadSuccess={this.props.onBenefitsLoadSuccess}
            isLoaded={this.props.isLoaded}
            isArchive={this.props.isArchive}
            isDraft={this.props.isDraft}
            onLanguagesLoadSuccess={this.props.onLanguagesLoadSuccess}
            benefitStats={this.props.benefitStats}
            hiddenBenefits={this.props.hiddenBenefits}
            benefitOrder={this.props.benefitOrder}
            changeBenefitOrder={this.props.changeBenefitOrder}
          />
        </Wrapper>
      </BenefitsWrap>
      </FirebaseContainer>
      </FirebaseContainer>
    );
  }
}

export default BenefitPage;
