/**
 * @flow
 */

import React, {
  Component,
} from 'react';

import SubHeader from './SubHeader';
import MessageForm from './MessageForm';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import {
  buildMessageArchives,
  buildMessageDrafts,
  buildMessages,
  buildTokens
} from '../../../../common/config/firebase_config/endpoints';
import type {TProfileData} from '../../../../common/types/models/TAuth';
import find from "lodash/find"

type TProps = {
  profile: TProfileData;
}

class MessageEdit extends Component<void, TProps, void> {
  _getQueries = () => {
    return ([{
      endpoint: this.props.isArchive ?
          buildMessageArchives(this.props.accountId) :
          this.props.isDraft ?
              buildMessageDrafts(this.props.accountId) :
              buildMessages(this.props.accountId),
      method: 'on',
      eventType: 'value',
      callback: this.props.isArchive ?
          this.props.onMessageArchivesLoadSuccess :
          this.props.isDraft ?
              this.props.onMessageDraftsLoadSuccess :
              this.props.onMessagesLoadSuccess,
      metadata: this.props.accountId,
    },
      // {
      //   endpoint: buildTokens(this.props.accountId),
      //   method: 'on',
      //   eventType: 'value',
      //   callback: this.props.onTokensLoadSuccess,
      // }
    ]);
  };

  _getItem = () => {
    const id = this.props.match.params.id;
    return find(this.props.messages || [], b => b.messageId === id)
  }

  _getForm = (message) => {
    let initials = message || {};
    return (
      <MessageForm
        updateMessage={this.props.updateMessage}
        updateMessageDraft={this.props.updateMessageDraft}
        messageFormValues={this.props.messageFormValues}
        hasMessage={message || false}
        message={message}
        tokens={this.props.tokens}
        accountId={this.props.accountId}
        isDraft={this.props.isDraft}
        isArchive={this.props.isArchive}
        history={this.props.history}
        accounts={this.props.accounts}
        initialValues={initials}/>
    );
  };

  render() {
    const {profile, isArchive, isDraft} = this.props;

    const message = this.props.messages ? this._getItem() : null
    const hasMessage = message ? true : false

    return (
      <div>
        <SubHeader isDraft={isDraft} isArchive={isArchive} hasMessage={hasMessage} messageId={message?.messageId}/>
        <FirebaseContainer queries={this._getQueries()}>
          <div>
            {this.props.messages ? this._getForm(message) : null}
          </div>
        </FirebaseContainer>
      </div>
    );
  }
}

export default MessageEdit;
